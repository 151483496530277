import { useState, useEffect } from "react";

import { Openings } from "../components/openings";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";
import { Referral } from "../components/referral";
import { Application } from "../components/application";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const Careers = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Openings data={landingPageData.Openings} />
      <Referral />
      <Application data={landingPageData.Openings} />
    </div>
  );
};
