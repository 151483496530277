export const Footer = () => {
  return (
    <div id="footer">
      <div className="container text-center">
        <div className="col-md-12 col-md-6">
          <img
            src="img/everify.png"
            className="img-responsive"
            alt=""
            height="50px"
            width="150px"
          />
        </div>
        <p>&copy; 2022 Quanterian Global Services LLP</p>
      </div>
    </div>
  );
};
