import { useState } from "react";
export const Application = (props) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    subject: "",
    message: "",
    resume: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      resume: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, such as sending data to a backend server
    console.log(formData);
    setFormData({
      fullName: "",
      email: "",
      contactNumber: "",
      subject: "",
      message: "",
      resume: null,
    });
  };
  const formContainerStyle = {
    maxWidth: "600px", // Set a max-width for the form container
    margin: "0 auto", // Center the form container within its parent
    padding: "20px", // Add some padding around the form
    textAlign: "center", // Center align the text
    fontFamily: "Arial, sans-serif",
    color: "#333",
  };

  const inputStyle = {
    marginBottom: "10px",
    width: "100%",
    height: "35px",
    borderColor: "#ccc",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "4px",
    padding: "5px 20px",
    boxSizing: "border-box", // Include padding in width and height
    margin: "5px 0", // Spacing between inputs
  };
  const updatedInputStyle = {
    ...inputStyle,
    marginBottom: "15px", // Consistent bottom margin for all inputs
  };

  const textAreaStyle = {
    ...inputStyle,
    height: "100px", // Larger height for text area
  };

  const fileInputStyle = {
    ...inputStyle,
    height: "auto", // Reset height for file input
  };

  const labelStyle = {
    display: "block",
    margin: "20px 0 5px 0", // Increased space above label
  };

  const updatedLabelStyle = {
    ...labelStyle,
    textAlign: "left", // Align text to the left for labels
    display: "inline-block", // Make labels inline-block to respect text-align
    width: "100%", // Ensure labels take up the full width
  };
  const buttonStyle = {
    backgroundColor: "#007bff", // Bootstrap primary color
    color: "white",
    padding: "10px 15px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    marginTop: "20px", // Space above the button
  };
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Application Form</h2>
              <div style={formContainerStyle}>
                <form onSubmit={handleSubmit}>
                  <div style={{ marginBottom: "20px" }}>
                    <label style={updatedLabelStyle}>
                      Full Name *
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        style={inputStyle}
                        required
                      />
                    </label>
                    <label style={updatedLabelStyle}>
                      E-mail *
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        style={updatedInputStyle}
                        required
                      />
                    </label>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <label style={updatedLabelStyle}>
                      Contact Number *
                      <input
                        type="tel"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        style={updatedInputStyle}
                        required
                      />
                    </label>
                    <label style={updatedLabelStyle}>
                      Upload Resume *
                      <input
                        type="file"
                        name="resume"
                        onChange={handleFileChange}
                        style={fileInputStyle}
                        required
                      />
                    </label>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <label style={updatedLabelStyle}>
                      Subject *
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        style={updatedInputStyle}
                        required
                      />
                    </label>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <label style={updatedLabelStyle}>
                      Message *
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        style={textAreaStyle}
                        required
                      />
                    </label>
                  </div>
                  <button type="submit" style={buttonStyle}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {/* Keep the image as is if needed */}
            <img src="img/application.png" className="img-responsive" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
