import React, { useState } from "react";

const AccordionItem = ({ title, description, bullets }) => {
  const [isOpen, setIsOpen] = useState(false);

  const styles = {
    containerStyle: {
      padding: "10px",
      borderBottom: "1px solid #ddd",
      margin: "5px 0",
    },
    titleStyle: {
      fontWeight: "bold",
      color: "#0056b3",
      cursor: "pointer",
    },
    descriptionStyle: {
      padding: "10px 0",
      color: "#000",
      display: isOpen ? "block" : "none",
    },
    bulletListStyle: {
      listStyleType: "disc",
      marginLeft: "20px",
    },
  };

  return (
    <div style={styles.containerStyle}>
      <div style={styles.titleStyle} onClick={() => setIsOpen(!isOpen)}>
        {title}
      </div>
      <div style={styles.descriptionStyle}>
        <p>{description}</p>
        <ul style={styles.bulletListStyle}>
          {bullets.map((bullet, index) => (
            <li key={index}>{bullet}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AccordionItem;
