export const Referral = () => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Employee Referral</h2>
          <p>For Quanterian Employees: Need some extra money?</p>
        </div>
        <div style={{ textAlign: "left" }}>
          <h3>Refer a friend to Quanterian</h3>
          <p>
            This is a reminder to employees, that you may earn a $500 (Five
            Hundred Dollars) bonus for the Referral of a new employee who
            completes 90 days of service.
          </p>
          <p>
            We at QUANTERIAN trust your ability to provide out company with
            qualified and outstanding referrals. Should you have any questions
            regarding the Employee Bonus Referral Program (EBRP) please contact
            our office, 512-540-5145
          </p>
          <p>
            Quanterian believes an effective way of increasing the success of
            hiring and retaining good people can fall in the hands of its own
            employees. The company encourages employees to refer friends,
            colleagues, and people you previously worked with for job vacancies.
            To reward employees for referring qualified candidates who
            subsequently are hired, Quanterian LLC. pays the referring employee
            a cash bonus of $500 for each successful referral made in accordance
            with the following general provisions. A current list of open
            positions may be found on our website.
          </p>
          <h3>Referral Bonus Payment Procedure</h3>
          <p>
            Employees who refer a qualified candidate who subsequently is hired
            and remains employed for at least 90 days are eligible for a
            referral bonus payment of $500. This bonus is paid in one
            installment - a cash payment of $500 after the new hire completes 90
            days of employment. All bonuses paid under this program are subject
            to tax withholding.
          </p>
          <h3>Referral Bonus Eligibility Criteria</h3>
          <p>
            No referral bonuses are paid for referrals of candidates who are
            rehires or persons returning from a leave of absence. To be
            eligible, the referring employee must still be in the company’s
            payroll at the specified dates. Additionally, to avoid possible
            conflicts of interest, referral bonuses are not paid for job
            candidates referred by management officials or employees working in
            the Human Resources Department. Any appropriate conduct related to
            this program will cause Quanterian to deny bonus payments.
          </p>
          <h3>Enforcement Responsibility</h3>
          <p>
            In accordance with the job-posting program, all open positions for
            which employees can refer qualified candidates are posted in the
            lunchroom and at other designated locations and maintained by the
            Human Resources Department. The Department also is responsible for
            administering the employee referral program, including processing
            all referral forms and bonus applications and resolving any
            disputes.
          </p>
        </div>
      </div>
    </div>
  );
};
