export const Clients = (props) => {
  return (
    <div id="clients" className="text-center">
      <div className="container">
        <div className="px-10 col-md-10 col-md-offset-1 section-title">
          <h2>Our Clients</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-3 col-md-2">
                  <img
                    src={d.img}
                    alt=" "
                    // width={d.width}
                    // height={d.height}
                  />
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
