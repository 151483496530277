import React from "react";

import AccordionItem from "./accordianItem";

const Accordion = () => {
  const jobPositions = [
    {
      title: "Computer Systems Analyst",
      description:
        "Quanterian, LLC currently has openings for Computer System Analyst. Jobs located in Irving, TX and various unanticipated locations throughout the US.",
      bullets: [
        " Confer with clients regarding the nature of the information processing or computation needs a computer program is to address.",
        "Coordinate and link the computer systems within an organization to increase compatibility and so information can be shared.",
        "Test, maintain, and monitor computer programs and systems, including coordinating the installation of computer programs and systems.",
        "Work on incremental deliveries, including immediate analysis and fix of critical issues.",
        "Work with J2EE, AWS, Oracle, PC SAS, Microsoft SQL Server and Power BI.",
        "Travel/relocate to various unanticipated locations throughout the U.S. for long and short term assignments at client sites.",
        "Requires Bachelor's degree or foreign equivalent in Computer Science, Engineering (any), or related and 2 years of experience in the job offered or as a Computer Software Professional.",
        "Offered salary $91562.00 P/Y.",
        "Send resume with cover letter to Quanterian, LLC, Job 23QUA01, 511 East John Carpenter Freeway, Ste. 500, Irving, TX 75062. ",
      ],
    },
    {
      title: "Python Developer",
      description:
        "Quanterian, LLC currently has openings for Python Developer. Jobs located in Irving, TX and various unanticipated locations throughout the US.",
      bullets: [
        "Design, build, and maintain efficient, reusable, and reliable machine learning models for various applications",
        "Utilize Flask, a lightweight WSGI web application framework, to create and maintain robust web applications.",
        "Handle data preprocessing, cleaning, and analysis to ensure the quality and accuracy of data fed into ML models.",
        "Develop RESTful APIs using Flask to facilitate communication between front-end interfaces and backend systems, including ML models.",
        "Optimize machine learning algorithms for speed and performance, and ensure they scale effectively with increasing data loads.",
        "Work closely with data scientists to translate statistical and machine learning models into robust, production-level code.",
        "Implement automated testing platforms and unit tests, and integrate them into CI/CD pipelines for Flask-based applications.",
        "Create comprehensive documentation for machine learning methodologies and Flask applications, and prepare reports on their performance and improvements.",
        "Offered salary $100,000.00 P/Y",
        "Requires Bachelor's degree or foreign equivalent in Computer Science, Engineering (any), with 3 years of professional experience",
      ],
    },
    {
      title: "DevOps Engineer",
      description:
        "Quanterian, LLC currently has openings for DevOps Engineer. Jobs located in Irving, TX and various unanticipated locations throughout the US.",
      bullets: [
        "Utilize various automation tools to streamline the software development lifecycle, enhancing efficiency and reducing time-to-market.",
        "Design, build, and maintain CI/CD pipelines to facilitate rapid and safe code deployment.",
        "Employ IaC tools like Terraform or AWS CloudFormation to manage and provision cloud resources systematically and repeatably.",
        "Diagnose and resolve issues in development, test, and production environments, ensuring system reliability and stability.",
        "Work closely with development, testing, and operations teams to ensure seamless integration and deployment of software solutions.",
        "Manage containerized applications using tools like Docker and orchestrate them using Kubernetes or similar platforms.",
        "Create and maintain detailed documentation for system configurations, procedures, and troubleshooting guides to ensure efficient knowledge transfer and continuity.",
        "Offered salary $100,000.00 P/Y",
        "Requires Bachelor's degree or foreign equivalent in Computer Science, Engineering (any), with 3 years of professional experience",
      ],
    },
    {
      title: "Quality Assuarance Engineer",
      description:
        "Quanterian, LLC currently has openings for QA Engineer. Jobs located in Irving, TX and various unanticipated locations throughout the US.",
      bullets: [
        " Review, evaluate and test client/server, web applications and mobile Applications.",
        "Gather and evaluate user requirements and procedures.",
        "Automate test cases and test plans for functional and regression testing of applications.",
        "Design E2E test plans and user guides to support certification process through commercial launch of DSDS devises.", 
        "Work with various technologies such as 5G DSDS, Confluence, Jira, Java, Selenium, Soap UI, RDMS like Oracle, PostMan, JMeter.", 
        "Travel/relocate to various unanticipated locations throughout the U.S. for long and short term assignments at client sites.", 
        "Requires Bachelor's degree or foreign equivalent in Computer Science, Information Technology, Engineering (any), or related and 6 months of experience in the job offered or as a Computer Software Professional.", 
        "Offered salary $81,328.00 P/Y.", 
        "Email resume with cover letter to legal@quanterian.com"
      ],
    },
  ];

  return (
    <div>
      {jobPositions.map((job, index) => (
        <AccordionItem
          key={index}
          title={job.title}
          description={job.description}
          bullets={job.bullets}
        />
      ))}
    </div>
  );
};

export default Accordion;
